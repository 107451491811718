import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
// TODO : 
import IdleTimerComponent from '../components/IdleTimer';
import PrivateRoute from './PrivateRouter';
import Landing from '../pages/Landing Page';
import GISDashboard from '../pages/Dashboard/GIS Dashboard';
import Login from '../pages/Login';

import SuspenseFallback from '../components/SuspenseFallback';
import TokenContextProvider from '../context/auth.context';
import AssetInfoContextProvider from '../context/assetInfo.context';

const LazyDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Dashboard Menu')),
      100
    );
  });
});

const MapComponent = lazy(() => import('../pages/Dashboard/MapComponent'));
const RoleMenu = lazy(() => import('../pages/Dashboard/Admin/Role Menu'));

const LazyClass = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('../pages/Dashboard/Master Class')), 2000);
  });
});

const LazyCategory = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Master Category')),
      2000
    );
  });
});

const LazyFixedAsset = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('../pages/Dashboard/Fixed Asset')), 2000);
  });
});

const LazyExpense = lazy(() => import('../pages/Dashboard/Expense'));

const LazyUser = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('../pages/Dashboard/User')), 2000);
  });
});

const LazyImages = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('../pages/Util/Image')), 2000);
  });
});

const LazyMenu = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Admin/Menu Master')),
      2000
    );
  });
});

const LazyAnnexure1 = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Annexure/Annexure1')),
      2000
    );
  });
});

const LazyAnnexure2 = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Annexure/Annexure2')),
      2000
    );
  });
});

const LazyAnnexure3 = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Annexure/Annexure3')),
      2000
    );
  });
});

const LazyConsolidatedAsset = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          import('../pages/Dashboard/Annexure/Consolidated Asset Register')
        ),
      2000
    );
  });
});

const LazyRole = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import('../pages/Dashboard/Admin/Role Master')),
      2000
    );
  });
});

const Routes = () => {
  return (
    <>
      <AssetInfoContextProvider>
        <TokenContextProvider>
          <SimpleReactLightbox>
            <Router>
              <Suspense fallback={<SuspenseFallback />}>
                {/* <IdleTimerComponent> */}
                <Switch>
                  <Route path='/' exact component={Landing} />
                  <Route path='/login' exact component={Login} />
                  <PrivateRoute
                    path='/dashboard'
                    exact
                    component={LazyDashboard}
                  />
                  <PrivateRoute
                    path='/gisdashboard'
                    exact
                    component={GISDashboard}
                  />

                  <PrivateRoute path='/class' exact component={LazyClass} />
                  <PrivateRoute
                    path='/category'
                    exact
                    component={LazyCategory}
                  />
                  <PrivateRoute
                    path='/fixedasset'
                    component={LazyFixedAsset}
                    exact
                  />
                  <PrivateRoute
                    path='/fixedasset'
                    component={LazyFixedAsset}
                    exact
                  />

                  <PrivateRoute path='/users' exact component={LazyUser} />
                  <PrivateRoute path='/expense' exact component={LazyExpense} />
                  <PrivateRoute path='/menumaster' exact component={LazyMenu} />
                  <PrivateRoute path='/rolemaster' exact component={LazyRole} />
                  <PrivateRoute path='/rolemenu' exact component={RoleMenu} />
                  <PrivateRoute
                    path='/suspense'
                    exact
                    component={SuspenseFallback}
                  />
                  <PrivateRoute
                    path='/assetmap'
                    exact
                    component={MapComponent}
                  />

                  {/* Annexure Routes */}
                  <PrivateRoute
                    path='/annexure1'
                    exact
                    component={LazyAnnexure1}
                  />
                  <PrivateRoute
                    path='/annexure2'
                    exact
                    component={LazyAnnexure2}
                  />
                  <PrivateRoute
                    path='/annexure3'
                    exact
                    component={LazyAnnexure3}
                  />
                  <PrivateRoute
                    path='/consolidatedasset'
                    exact
                    component={LazyConsolidatedAsset}
                  />
                  {/* utilities */}
                  <PrivateRoute
                    path='/imagestore'
                    exact
                    component={LazyImages}
                  ></PrivateRoute>
                </Switch>
                {/* </IdleTimerComponent> */}
              </Suspense>
            </Router>
          </SimpleReactLightbox>
        </TokenContextProvider>
      </AssetInfoContextProvider>
    </>
  );
};
export default Routes;
