import React, { useState, useEffect, useRef } from 'react';
import { Map, View } from 'ol';
import Overlay from 'ol/Overlay';
import { transform } from 'ol/proj';
import { useRecoilState } from 'recoil';
import { Attribution, defaults as defaultControls } from 'ol/control';
import MapType from './MapType';
import MapControl from './MapControl';
import { MapPopup } from '../../../components';
import CustomCollapse from '../../../components/CustomCollapse';
import { cardWidthAtom } from '../../../recoil/atom/GIS Dashboard/cardWidth.atom';
import { cardHeadingTextAtom } from '../../../recoil/atom/GIS Dashboard/CardHeaderText.atom';
import { ContainerVisibilityStatusAtom } from '../../../recoil/atom/GIS Dashboard/ContainerVisibilityStatus.atom';
import { RoadMap, Compass, MarkerIcon } from '../../../assets/images';
import { Circle as CircleStyle, Fill, Stroke, Icon, Style } from 'ol/style';

import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';

import {
  osm,
  odishaDistrictBoundary,
  odishaBlockBoundary,
  odishaGrampanchayatBoundary,
  odishaRevenueVillageBoundary,
  googleLayerSatellite,
  googleLayerRoadMap,
  bingMap,
} from './Layers';
import { assetInformationForPlotAtom } from '../../../recoil/atom/GIS Dashboard/assetInformationForPlot.atom';

function GisMap() {
  var initialMap;
  const ref = useRef(null);
  const mapElement = useRef();
  const popupRef = useRef();
  const popupContent = useRef();

  const [name, setName] = useState();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [coordinate, setCoordinate] = useState([]);
  const [popupData, setPopupData] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [overlayOfMap, setOverlayOfMap] = useState();

  const [image, setImage] = useState(RoadMap);
  const [layerName, setLayerName] = useState('OSM');

  const [previousSizeOfMap, setPreviousSizeOfMap] = useState();
  const [cardWidth, setCardWidth] = useRecoilState(cardWidthAtom);
  const [headerText, setHeaderText] = useRecoilState(cardHeadingTextAtom);

  const [containerVisibilityStatus, setContainerVisibilityStatus] =
    useRecoilState(ContainerVisibilityStatusAtom);

  const [assetInfoForPlotting, setAssetInfoForPlotting] = useRecoilState(
    assetInformationForPlotAtom
  );

  // FIXME: custom attribution
  const attribution = new Attribution({
    collapsible: false,
    html: 'Where it came from',
  });

  const cardHeadingStyle = {
    fontSize: '15px',
    letterSpacing: '1px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'gray',
  };

  const collapseContainerStyle = {
    position: 'absolute',
    top: '67px',
    right: '3rem',
    bottom: '0',
  };

  // modal handler for control
  const openModalHandler = (size, text) => {
    setCardWidth(size);
    setHeaderText(text);
    setOpen(true);
    setSelected(text);
  };

  // on click handler for control icon click on overlay map
  const handleClickControl = (event, text) => {
    event.preventDefault();

    switch (text) {
      case 'Home':
      // TODO:  reset the map
      case 'Legends':
        openModalHandler(15, text);
        break;
      case 'Filter':
        openModalHandler(22, text);
        break;
      case 'Layers':
        openModalHandler(15, text);
        break;
      case 'Fullscreen':
        setSelected(text);
        containerVisibilityStatus && setSelected('');
        open && setOpen(false);
        if (!containerVisibilityStatus) {
          let size = name.getSize();
          setPreviousSizeOfMap(size);
          name.updateSize();
          name.setSize([2300, 1200]);
        } else {
          name.updateSize();
          name.setSize(previousSizeOfMap);
        }
        setContainerVisibilityStatus(!containerVisibilityStatus);
        break;

      default:
        break;
    }
  };

  //close modal controller
  const handleCloseCollapse = (e) => {
    e.preventDefault();
    setOpen(false);
    setSelected('');
  };

  const initializeMap = () => {
    initialMap = new Map({
      target: mapElement.current,
      controls: defaultControls({ attribution: false }).extend([attribution]),
      layers: [
        osm,
        googleLayerSatellite,
        googleLayerRoadMap,
        bingMap,
        odishaDistrictBoundary,
        odishaBlockBoundary,
        odishaGrampanchayatBoundary,
        odishaRevenueVillageBoundary,
      ],

      view: new View({
        center: transform([85.21, 20.62157], 'EPSG:4326', 'EPSG:3857'),
        zoom: 7,
      }),
    });

    initialMap.on('click', handleMapClick);
    setName(initialMap);
  };

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    const iconStyle = new Style({
      image: new Icon({
        // anchor: [0.5, 1],
        crossOrigin: '',
        // imgSize: [30, 48],
        size: [100, 100],
        src: MarkerIcon,
      }),
    });

    var geojsonFeatures = new GeoJSON({
      featureProjection: 'EPSG:3857',
    });

    var featurePoint = geojsonFeatures.readFeatures(assetInfoForPlotting);

    var vectorSource = new VectorSource({});
    vectorSource.clear();
    vectorSource.addFeatures(featurePoint);
    new VectorLayer({
      map: name,
      source: vectorSource,
      style: [iconStyle],
    });
  }, [assetInfoForPlotting]);

  // map click handler
  const handleMapClick = async (event) => {
    event.preventDefault();

    var jsonData = assetInfoForPlotting?.features[0]?.properties;

    // convert this json data into an array
    let convertedArray = [];

    // for (var key in jsonData) {
    //   if (jsonData.hasOwnProperty(key)) {
    //     let val = jsonData[key];
    //     let pushJson = {
    //       nameValue: key,
    //       value: val,
    //     };
    //     convertedArray.push(pushJson);
    //   }
    // }
    // await setPopupData(convertedArray);

    setPopupShow(!popupShow);
    const coordinate = event.coordinate;
    setCoordinate(coordinate);

    const overlay = new Overlay({
      element: popupRef.current,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    setOverlayOfMap(overlay);
    initialMap.addOverlay(overlay);
    overlay.setPosition(coordinate);
  };

  // zoom control handler
  const handleZoomHandler = () => {
    var view = name.getView();
    var coordinateNew = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
    view.setCenter(transform(coordinateNew, 'EPSG:4326', 'EPSG:3857'));

    view.animate({
      zoom: view.getZoom() + 1,
      duration: 250,
    });
  };

  // close the popup
  const onClosePopupHandler = (e) => {
    e.preventDefault();
    overlayOfMap.setPosition(undefined);
    return false;
  };

  // remove all layer boundaries with async
  const removeAllLayers = () => {
    [0, 1, 2, 3].map(
      (i) => (name.getLayers().array_[i].values_.visible = false)
    );
    name.render();
  };

  const setVisibility = async (id) => {
    let visibility = name.getLayers().array_[id].values_.visible;
    visibility && id !== 0
      ? (name.getLayers().array_[id].values_.visible = false)
      : (name.getLayers().array_[id].values_.visible = true);
    name.render();
  };

  // on Click handler for boundary icons
  const onClickImage = (id) => {
    return (url) => {
      return (name) => {
        setImage(url);
        setLayerName(name);
        removeAllLayers();
        id !== -1 && setVisibility(id);
      };
    };
  };

  const mapControlProps = {
    handleClickControl,
    selected,
  };

  const mapTypeProps = {
    name,
    onClickImage,
    image,
    layerName,
  };

  const popupProps = {
    popupData,
    onClosePopupHandler,
    handleZoomHandler,
    onClickImage,
  };

  return (
    <>
      <div
        className='mapRowGis'
        ref={ref}
        style={{
          height: containerVisibilityStatus ? '91vh' : '66vh',
        }}
      >
        <div ref={mapElement} className='map-container' />
        <div className='compass-section'>
          <img src={Compass} height={100} alt='compass' />
        </div>

        {/* Controls */}
        <MapControl {...mapControlProps} />
        {popupShow && (
          <div className='ol-popup' ref={popupRef}>
            <div ref={popupContent}>
              <MapPopup {...popupProps} />
            </div>
          </div>
        )}

        {/* Map type section */}
        <MapType {...mapTypeProps} />

        {/* Collapse Container */}
        <CustomCollapse
          open={open}
          cardHeadingStyle={cardHeadingStyle}
          collapseContainerStyle={collapseContainerStyle}
          headerText={headerText}
          handleCloseCollapse={handleCloseCollapse}
          name={name}
        />
      </div>
    </>
  );
}

export default GisMap;
