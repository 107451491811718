import React from 'react';
import { useHistory } from 'react-router';
import { Navbar, Container, Nav, Row, Col, Offcanvas } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png';
import NaveenJi from '../../assets/images/naveen-patnaik-header.png';

// Nav brand section (Log0 and sections)
export const NavBrand = () => {
  return (
    <>
      <Navbar.Brand className='d-flex flex-column'>
        <Row>
          <Col xs={5}>
            <img src={Logo} alt='logo' height={60} />
          </Col>
          <Col xs={7} style={{ fontSize: '14px' }}>
            <Row>ଅର୍ଥ ବିଭାଗ</Row>
            <Row>Finance Department</Row>
            <Row>Government of Odisha</Row>
          </Col>
        </Row>
      </Navbar.Brand>
    </>
  );
};

// navbar nav links
export const NavLinks = () => {
  const history = useHistory();
  return (
    <>
      <Nav
        className='ms-5 me-auto nav-items-header'
        style={{ fontSize: '18px', color: 'black', fontWeight: '400', fontFamily: 'Roboto, sans-serif', letterSpacing: 1}}
      >
        <Nav.Link>Home</Nav.Link>
        <Nav.Link href='#about'>About</Nav.Link>
        <Nav.Link onClick={() => history.push('/login')}>Login</Nav.Link>
      </Nav>
    </>
  );
};

// navbar offcanvas
export const NavbarOffcanvas = (props) => {
  const history = useHistory();
  const { showOffcanvas, setShowOffcanvas } = props;

   const OffcanvasHeaderStyle = {
    color: '#1E2960',
    fontSize: '22px',
    fontWeight: '700',
    letterSpacing: '4px',
  };

  const OffcanvasLinksStyle = {
    color: '#1E2960',
    fontSize: '17px',
    fontWeight: '400',
    letterSpacing: '1px',
  };
  return (
    <>
      <Navbar.Offcanvas
        show={showOffcanvas}
        id='offcanvasNavbar'
        aria-labelledby='offcanvasNavbarLabel'
        placement='end'
        style={{
          backgroundColor: '#fffbfb',
          color: '#281f1f',
          opacity: '0.9',
        }}
      >
        <Offcanvas.Header
          closeButton
          onHide={() => setShowOffcanvas(!showOffcanvas)}
        >
          <Offcanvas.Title
            id='offcanvasNavbarLabel'
            style={OffcanvasHeaderStyle}
          >
            <span>OPAMS</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className='me-auto' style={OffcanvasLinksStyle}>
            <Nav.Link
              onClick={() => setShowOffcanvas(!showOffcanvas)}
              style={OffcanvasLinksStyle}
            >
              Home
            </Nav.Link>
            <Nav.Link
              style={OffcanvasLinksStyle}
              onClick={() => {
                setShowOffcanvas(!showOffcanvas);
              }}
              name='#about'
            >
              About
            </Nav.Link>
            <Nav.Link
              style={OffcanvasLinksStyle}
              onClick={() => history.push('/login')}
            >
              Login
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  );
};

const NavbarHeader = () => {
  const [showOffcanvas, setShowOffcanvas] = React.useState(false);
  return (
    <div>
      <Navbar
        bg='light'
        expand='lg'
        fixed='top'
        style={{
          backgroundColor: '#fff',
          color: '#000',
          borderBottom: '1px solid #d3d3d3',
        }}
        className='container-fluid'
      >
        <Container>
          <NavBrand />
          <NavLinks />
          <Nav className='naveenJi-logo-header'>
            <Nav.Item className='m-0 p-0'>
              <img src={NaveenJi} alt='naveenJi' height={70} />
            </Nav.Item>
          </Nav>
          <Navbar.Toggle
            aria-controls='offcanvasNavbar'
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />

          <NavbarOffcanvas
            showOffcanvas={showOffcanvas}
            setShowOffcanvas={setShowOffcanvas}
          />
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarHeader;
